<template>
    <div>
        <v-form lazy-validation ref="paymentTransactionsForm" v-model="validForm">
            <v-data-table
                    :headers="headers"
                    :items="PaymentTransactions"
                    calculate-widths
                    class="mt-3 appic-table-light specification-table"
                    dense
                    disable-pagination
                    disable-sort
                    hide-default-header
                    hide-default-footer
                    item-key="index"
                    single-select
            >
                <template v-slot:header="{ props: { headers } }">
                    <tr>
                        <th class="font-sm grey lighten-3 pa-1 text-left">{{ headers[0].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-left">{{ headers[1].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-left width-15-pct">{{ headers[2].text }}</th>
                        <th class="font-xs grey lighten-3 pa-1 text-left text-no-wrap">
                            <div class="d-flex flex-column">
                                <span>{{ $t('message.systemCreateDate') }}</span>
                                <span>{{ $t('message.systemUpdateDate') }}</span>
                            </div>
                        </th>
                        <th class="font-sm grey lighten-3 py-1 pl-1 pr-6 text-left width-15-pct">{{ headers[4].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-left width-10-pct">{{ headers[5].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-left">{{ headers[6].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-left width-15-pct">{{ headers[7].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-left">{{ headers[8].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-center width-1-pct" v-if="paymentType == 'Payment'">{{ headers[9].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-left width-15-pct" v-if="paymentType == 'PoPayment'">{{ headers[9].text }}</th>
                        <th class="font-sm grey lighten-3 pa-1 text-center width-1-pct" v-if="paymentType == 'PoPayment'">{{ headers[10].text }}</th>
                    </tr>
                </template>
                <template v-slot:item.Paymenttransaction.payer_id="{ item }">
                    <div v-if="paymentType == 'Payment' && documentType == 'Contract'" class="text-no-wrap">{{ Contract.Customer.title }}</div>
                    <div v-if="paymentType == 'PoPayment' && documentType == 'Contract'" class="text-no-wrap">{{ Contract.Office.title }}</div>
                    <div v-if="paymentType == 'Payment' && documentType == 'Localsale'" class="text-no-wrap">{{ Localsale.Customer.title }}</div>
                    <div v-if="paymentType == 'PoPayment' && documentType == 'Epr'" class="text-no-wrap">{{ Epr.Customer.title }}</div>
                </template>
                <template v-slot:item.Paymenttransaction.payee_id="{ item }">
                    <div v-if="paymentType == 'Payment' && Contract.salestype_id == 2 && documentType == 'Contract'" class="text-no-wrap">{{ Contract.Supplier.title }}</div>
                    <div v-if="paymentType == 'Payment' && Contract.salestype_id != 2 && documentType == 'Contract'" class="text-no-wrap">{{ Contract.Office.title }}</div>
                    <div v-if="paymentType == 'PoPayment' && Contract.salestype_id != 2 && documentType == 'Contract'" class="text-no-wrap">{{ Contract.Supplier.title }}</div>
                    <div v-if="paymentType == 'Payment' && documentType == 'Localsale'" class="text-no-wrap">{{ Localsale.Office.title }}</div>
                    <div v-if="paymentType == 'PoPayment' && documentType == 'Epr'" class="text-no-wrap">{{ Epr.Supplier.title }}</div>
                </template>
                <template v-slot:item.Paymenttransaction.paymentdate="{ item }">
                    <v-menu
                            :close-on-content-click="true"
                            :nudge-right="40"
                            :ref="item.paymentDateMenu"
                            max-width="290px"
                            min-width="290px"
                            transition="scale-transition"
                            v-model="item.paymentDateMenu"
                    >
                        <template v-slot:activator="{ on }">
                            <v-text-field
                                    :label="item.Paymenttransaction.paymentdate ? '' : $t('message.required')"
                                    :rules="[...validationRules.required, ...validationRules.formattedDate]"
                                    :value="formatPaymentDate(item.Paymenttransaction.paymentdate)"
                                    append-icon="event"
                                    clearable
                                    class="force-text-left custom pa-1 bg-lightgrey"
                                    hide-details="auto"
                                    persistent-hint
                                    readonly
                                    solo
                                    v-on="on"
                            />
                        </template>
                        <v-date-picker
                                first-day-of-week="1"
                                locale-first-day-of-year="4"
                                show-week
                                @change="updateTransactionRowHandle(item.index, 'Paymenttransaction.paymentdate',$event)"
                                @input="item.paymentDateMenu = false"
                        />
                    </v-menu>
                </template>
                <template v-slot:item.Paymenttransaction.created="{ item }">
                    <div class="d-flex flex-column">
                        <span>{{ formatDate(item.Paymenttransaction.created) }}</span>
                        <span class="mt-2">{{ formatDate(item.Paymenttransaction.modified) }}</span>
                    </div>
                </template>
                <template v-slot:item.Paymenttransaction.amount="{ item }">
                    <div class="d-flex flex-row">
                        <v-select
                                :items="allCurrencies"
                                :value="item.Paymenttransaction.currency_id"
                                class="custom pa-1 bg-lightgrey"
                                style="width: 100px !important;"
                                dense
                                hide-details="auto"
                                item-text="Currency.code"
                                item-value="Currency.id"
                                solo
                                @change="updateTransactionRowHandle(item.index, 'Paymenttransaction.currency_id',$event)"
                        />
                        <v-text-field
                                :rules="[...validationRules.required, ...validationRules.amount]"
                                :value="item.Paymenttransaction.amount"
                                class="custom pa-1 bg-lightgrey"
                                dense
                                hide-details="auto"
                                solo
                                @change="updateTransactionRowHandle(item.index, 'Paymenttransaction.amount',$event)"
                        />
                    </div>
                </template>
                <template v-slot:item.Paymenttransaction.bankcharge="{ item }">
                    <v-text-field
                            :rules="[...validationRules.requiredWithZero, ...validationRules.amount]"
                            :value="item.Paymenttransaction.bankcharge"
                            class="custom pa-1 bg-lightgrey"
                            dense
                            hide-details="auto"
                            solo
                            @change="updateTransactionRowHandle(item.index, 'Paymenttransaction.bankcharge',$event)"
                    />
                </template>
                <template v-slot:item.Paymenttransaction.isdeposit="{ item }">
                    <div class="d-flex flex-row align-center justify-center">
                        <v-checkbox
                                :true-value="1"
                                class="pl-1"
                                dense
                                hide-details
                                v-model="item.Paymenttransaction.isdeposit"
                                @change="paymentDepositSet($event)"
                        />
                    </div>
                </template>
                <template v-slot:item.Paymenttransaction.currencyrate="{ item }">
                    <div class="d-flex flex-row align-center">
                        <span class="text-no-wrap ml-1 mr-2">1 {{ getPaymentCurrency().code }} :</span>
                        <v-text-field
                                :rules="[...validationRules.required, ...validationRules.amount]"
                                :value="item.Paymenttransaction.currencyrate"
                                class="custom pa-1 bg-lightgrey"
                                dense
                                hide-details="auto"
                                solo
                                @change="updateTransactionRowHandle(item.index, 'Paymenttransaction.currencyrate',$event)"
                        />
                        <span class="ml-2 mr-1">{{ allCurrencies.find(c => c.Currency.id == item.Paymenttransaction.currency_id ).Currency.code }}</span>
                    </div>
                </template>
                <template v-slot:item.Paymenttransaction.receivable="{ item }">
                    <div class="text-no-wrap text-left">{{getPaymentCurrency().code + " " + formatThisNumber(item.Paymenttransaction.receivable, getPaymentCurrency().format) }}</div>
                </template>
                <template v-slot:item.Paymenttransaction.payment_slip="{ item }">
                    <div class="d-flex flex-row align-center">
                        <v-file-input
                                :placeholder="$t('message.selectFile')"
                                :value="{name: item.Paymenttransaction.payment_slip ? item.Paymenttransaction.payment_slip : '', type: ''}"
                                accept="image/png, image/jpeg, application/pdf"
                                class="custom pa-0"
                                dense
                                hide-details="auto"
                                solo
                                @change="updateTransactionRowHandle(item.index, 'Paymenttransaction.payment_slip',$event)"
                        />
                        <LoadingIndicator class="ml-2 d-flex flex-row" v-if="loading.upload[item.index]"/>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on: tooltip }">
                                <v-btn icon v-on="{ ...tooltip}" @click="viewPaymentSlip(item.Paymenttransaction.payment_slip)" v-if="paymentType == 'PoPayment' && item.Paymenttransaction.id != null && item.Paymenttransaction.payment_slip != ''">
                                    <v-icon small class="mr-2">fas fa-binoculars</v-icon>
                                </v-btn>
                            </template>
                            <span>{{ $t('message.viewSlip') }}</span>
                        </v-tooltip>
                    </div>
                </template>
                <template v-slot:item.Paymenttransaction.id="{ item }">
                    <div  class="d-flex flex-row align-center justify-center" style="height: 100%">
                        <v-menu>
                            <template v-slot:activator="{ on: menu }">
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on: tooltip }">
                                        <v-btn icon v-on="{ ...tooltip, ...menu}" class="ml-1">
                                            <v-icon>more_vert</v-icon>
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('message.moreActions') }}</span>
                                </v-tooltip>
                            </template>
                            <v-list dense>
                                <v-list-item class="font-sm" @click="deleteThisTransaction(item.index, item.Paymenttransaction.id)"><v-icon small class="mr-1" color="red lighten-1">cancel</v-icon>{{ $t('message.deleteThisTransaction') }}</v-list-item>
                                <v-list-item class="font-sm" @click="viewPaymentSlip(item.Paymenttransaction.payment_slip)" v-if="false"><v-icon small class="mr-2">fas fa-binoculars</v-icon>{{ $t('message.viewSlip') }}</v-list-item>
                                <v-list-item class="font-sm" @click="openSendSlipDialog(item.index, item.Paymenttransaction.id)" v-if="paymentType == 'PoPayment' && item.Paymenttransaction.id != null && item.Paymenttransaction.payment_slip != ''"><v-icon small class="mr-2">far fa-paper-plane</v-icon>{{ $t('message.sendSlipToSupplier') }}</v-list-item>
                                <v-list-item class="font-sm" @click="openNotificationToBuyerDialog(item.index, item.Paymenttransaction.id)" v-if="paymentType == 'Payment' && item.Paymenttransaction.id != null"><v-icon small class="mr-2">far fa-paper-plane</v-icon>{{ $t('message.sendNotificationToBuyer') }}</v-list-item>
                            </v-list>
                        </v-menu>
                    </div>
                </template>
                <template v-slot:footer>
                    <div class="pt-0 d-flex justify-space-between">
                        <div class="col-4 col-lg-4 col-md-5 pt-5 px-0 d-flex justify-start">
                            <v-btn
                                    :loading="loading.add"
                                    color="success"
                                    small
                                    @click="addNewTransactionRow()"
                            >{{ PaymentTransactions.length == 0 ? $t('message.addPayment') : $t('message.addMorePayments') }}
                            </v-btn>
                        </div>
                        <div class="dense-inputs col-3 col-lg-3 col-md-5 pa-0 d-flex flex-column">
                            <v-row no-gutters class="pt-3">
                                <v-col cols="6" class="font-sm font-weight-bold text-right">{{ $t('message.totalDue') }}:</v-col>
                                <v-col cols="6" class="font-sm font-weight-bold pl-3">{{ getPaymentCurrency().code + " " + formatThisNumber(paymentAmount, getPaymentCurrency().format) }}</v-col>
                            </v-row>
                            <v-row no-gutters v-if="Contract.salestype_id == 2 && paymentType == 'Payment'">
                                <v-col cols="6" class="font-sm font-weight-bold text-right">{{ Contract.adjustment_title ? Contract.adjustment_title : $t('message.adjustment') }}:</v-col>
                                <v-col cols="6" class="font-sm font-weight-bold pl-3">{{ getPaymentCurrency().code + " " + formatThisNumber(Contract.adjustment, getPaymentCurrency().format) }}</v-col>
                            </v-row>
                            <v-row no-gutters>
                                <v-col cols="6" class="font-sm font-weight-bold text-right">{{ $t('message.totalPaid') }}:</v-col>
                                <v-col cols="6" class="font-sm font-weight-bold green--text pl-3">{{ getPaymentCurrency().code + " " + formatThisNumber(totalPaid, getPaymentCurrency().format) }}</v-col>
                            </v-row>
                            <v-row no-gutters v-if="paymentType == 'Payment' && Contract.salestype_id != 2">
                                <v-col cols="6" class="font-sm font-weight-bold text-right">{{ totalOutstanding > 0 ? $t('message.totalOutstanding') : $t('message.totalExcess') }}:</v-col>
                                <v-col cols="6" class="font-sm font-weight-bold pl-3 red--text">{{ getPaymentCurrency().code + " " + formatThisNumber(totalOutstanding, getPaymentCurrency().format) }}</v-col>
                            </v-row>
                            <v-row no-gutters v-if="paymentType == 'Payment' && Contract.salestype_id == 2">
                                <v-col cols="6" class="font-sm font-weight-bold text-right">{{ totalOutstandingWithAdjustment > 0 ? $t('message.totalOutstanding') : $t('message.totalExcess') }}:</v-col>
                                <v-col cols="6" class="font-sm font-weight-bold pl-3 red--text">{{ getPaymentCurrency().code + " " + formatThisNumber(Math.abs(totalOutstandingWithAdjustment), getPaymentCurrency().format) }}</v-col>
                            </v-row>
                            <v-row no-gutters v-if="paymentType == 'PoPayment'">
                                <v-col cols="6" class="font-sm font-weight-bold text-right">{{ totalOutstanding > 0 ? $t('message.totalOutstanding') : $t('message.totalExcess') }}:</v-col>
                                <v-col cols="6" class="font-sm font-weight-bold pl-3 red--text">{{ getPaymentCurrency().code + " " + formatThisNumber(Math.abs(totalOutstanding), getPaymentCurrency().format) }}</v-col>
                            </v-row>
                        </div>
                    </div>
                </template>
            </v-data-table>
        </v-form>
        <SimpleAlert
                :alert_message.sync="dialogs.error_message"
                :dialog.sync="dialogs.error"
                @dialog-closed="dialogClosed"
        />
        <v-dialog
                persistent
                transition="dialog-bottom-transition"
                width="800px"
                v-if="paymentType == 'PoPayment'"
                v-model="sendSlipDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendThisPaymentSlipToSupplier') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0"></th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.shipping')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.accounts')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="supplierPaymentContacts.length > 0">
                                        <tr v-for="contact in supplierPaymentContacts" v-bind:key="contact.Contact.id">
                                            <td>
                                                <v-checkbox
                                                        :value="contact.Contact.email"
                                                        class="mt-1"
                                                        dense
                                                        hide-details
                                                        v-model="selectedSupplierContacts"
                                                />
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                            <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                            <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsshipping == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                            <td class="pt-2 pl-0 text-center">{{ contact.Contact.accounts == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="6">{{ $t('message.noContactsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer/>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="sendSlipDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.send"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendPaymentSlipToSupplier(selectedTransactionId, selectedSupplierContacts)"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
        <v-dialog
                persistent
                transition="dialog-bottom-transition"
                width="800px"
                v-if="paymentType == 'Payment'"
                v-model="sendBuyerNotificationDialog"
        >
            <v-card>
                <v-card-title class="success white--text">{{ $t('message.sendThisPaymentConfirmationToBuyer') }}</v-card-title>
                <v-card-text class="white">
                    <v-row no-gutters class="pt-3 align-center">
                        <v-col cols="12">
                            <h2>{{ $t('message.selectContacts') }}</h2>
                            <v-simple-table class="appic-table-light specification-tables" dense>
                                <template v-slot:default>
                                    <thead>
                                        <tr>
                                            <th class="grey lighten-3 pa-0"></th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.name')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.email')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.position')}}</th>
                                            <th class="grey lighten-3 pa-0 text-left">{{ $t('message.receiveNotifications')}}</th>
                                        </tr>
                                    </thead>
                                    <tbody v-if="buyerPaymentContacts.length > 0">
                                        <tr v-for="contact in buyerPaymentContacts" v-bind:key="contact.Contact.id">
                                            <td>
                                                <v-checkbox
                                                        :value="contact.Contact.email"
                                                        class="mt-1"
                                                        dense
                                                        hide-details
                                                        v-model="selectedBuyerContacts"
                                                />
                                            </td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.name }}</td>
                                            <td class="font-weight-bold pt-2 pl-0 text-left">{{ contact.Contact.email }}</td>
                                            <td class="pt-2 pl-0 text-left">{{ contact.Contact.position }}</td>
                                            <td class="pt-2 pl-0 text-center">{{ contact.Contact.otsnotification == 1 ? $t('message.yes') : $t('message.no') }}</td>
                                        </tr>
                                    </tbody>
                                    <tbody v-else>
                                        <tr>
                                            <td colspan="5">{{ $t('message.noContactsFound') }}</td>
                                        </tr>
                                    </tbody>
                                </template>
                            </v-simple-table>
                        </v-col>
                    </v-row>
                </v-card-text>
                <v-card-actions class="pr-3 pt-3 white">
                    <v-spacer></v-spacer>
                    <v-btn
                            class="ml-3"
                            color="default"
                            small
                            @click="sendBuyerNotificationDialog = false"
                    >{{ $t('message.cancel') }}
                    </v-btn>
                    <v-btn
                            :loading="loading.sendToBuyer"
                            class="ml-3"
                            color="info"
                            small
                            @click="sendNotificationToBuyer(selectedTransactionId, selectedBuyerContacts)"
                    >{{ $t('message.send') }}
                    </v-btn>
                </v-card-actions>
            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import { api } from "../../api";
import {mapFieldsPrefixed, mapMultiRowFieldsPrefixed} from "Helpers/vuex-map-fields-prefixed";
import {formatDate, numberFormat} from "Helpers/helpers";
import {mapFields} from "vuex-map-fields";
import {mapActions, mapGetters} from 'vuex';
import LoadingIndicator from "Components/Appic/LoadingIndicator";
import S3 from 'aws-s3-pro';
import SimpleAlert from "Components/Appic/SimpleAlert";

export default {
    name: "PaymentTransactionsList",
    components: {LoadingIndicator, SimpleAlert},
    props: ['documentType','paymentType','updateMode','validate'],
    data() {
        return {
            buyerContacts: [],
            dialogs: {
                error:  false,
                error_message: ""
            },
            loading: {
                add: false,
                send: false,
                sendToBuyer: false,
                upload: []
            },
            selectedBuyerContacts: [],
            selectedSupplierContacts: [],
            selectedTransactionId: null,
            sendBuyerNotificationDialog: false,
            sendSlipDialog: false,
            supplierContacts: [],
            validForm: true
        }
    },
    computed: {
        stateAndTypePrefix() {
            if (this.updateMode) {
                return 'update' + "." + this.paymentType
            } else {
                return 'current' + "." + this.paymentType
            }
        },
        statePrefix() {
            if (this.updateMode) {
                return 'update'
            } else {
                return 'current'
            }
        },
        ...mapMultiRowFieldsPrefixed('paymenttransaction',{
            PaymentTransactions: 'PaymentTransactions'
        },'stateAndTypePrefix'),
        ...mapFieldsPrefixed('payment',{
            Contract: 'Contract',
            Epr: 'Epr',
            Localsale: 'Localsale',
            Payment: 'Payment',
            PoPayment: 'PoPayment'
        }, 'statePrefix'),
        ...mapFieldsPrefixed('paymenttransaction',{
            totalPaid: 'totalPaid'
        }, 'stateAndTypePrefix'),
        ...mapFields('payment',{
            newPaymentSaved: 'newPaymentSaved',
            newPaymentUnsaved: 'newPaymentUnsaved',
            updatedPaymentUnsaved: 'updatedPaymentUnsaved',
            updatedPaymentSaved: 'updatedPaymentSaved'
        }),
        ...mapFields('runtime',{
            openedTabs: 'openedTabs'
        }),
        ...mapGetters('currency',{
            allCurrencies: 'allCurrencies'
        }),
        ...mapGetters('document',{
            allFinanceDocuments: 'allFinanceDocuments'
        }),
        ...mapGetters('user',{
            s3Keys : 's3Keys'
        }),
        ...mapGetters([
            'validationRules'
        ]),
        baseURL(){
            return 'https://apptimber-appic.s3-ap-southeast-1.amazonaws.com'
        },
        buyerPaymentContacts () {
            return this.buyerContacts.filter(contact => {
                return contact?.Contact?.otsnotification == 1
            })
        },
        config() {
            return {
                bucketName: 'apptimber-appic',
                dirName: 'payments',
                region: 'ap-southeast-1',
                accessKeyId: this.s3Keys.id,
                secretAccessKey: this.s3Keys.secret,
                s3Url: this.baseURL
            }
        },
        headers () {
            let headers = [
                {
                    id: 0,
                    text: this.$t('message.from'),
                    value: 'Paymenttransaction.payer_id'
                },
                {
                    id: 1,
                    text: this.$t('message.to'),
                    value: 'Paymenttransaction.payee_id'
                },
                {
                    id: 2,
                    text:  this.paymentType == 'PoPayment' ? this.$t('message.paymentSentDate') :  this.$t('message.paymentReceivedDate'),
                    value: 'Paymenttransaction.paymentdate'
                },
                {
                    id: 3,
                    text: this.$t('message.systemCreateDate'),
                    value: 'Paymenttransaction.created'
                },
                {
                    id: 4,
                    text: this.$t('message.amount'),
                    value: 'Paymenttransaction.amount'
                },
                {
                    id: 5,
                    text: this.$t('message.bankCharge'),
                    value: 'Paymenttransaction.bankcharge'
                },
                {
                    id: 6,
                    text: this.$t('message.deposit') + "?",
                    value: 'Paymenttransaction.isdeposit'
                },
                {
                    id: 7,
                    text: this.$t('message.exRate'),
                    value: 'Paymenttransaction.currencyrate'
                },
                {
                    id: 8,
                    text: this.paymentType == 'Payment' ? this.$t('message.receivable') : this.$t('message.payable'),
                    value: 'Paymenttransaction.receivable'
                }
            ]
            if(this.paymentType == 'PoPayment') {
                headers.push(
                    {
                        id: 9,
                        text: this.$t('message.slip'),
                        value: 'Paymenttransaction.payment_slip'
                    }
                )
                headers.push(
                    {
                        id: 10,
                        text: this.$t('message.actions'),
                        value: 'Paymenttransaction.id'
                    }
                )
            } else {
                headers.push(
                    {
                        id: 9,
                        text: this.$t('message.actions'),
                        value: 'Paymenttransaction.id'
                    }
                )
            }
            return headers
        },
        paymentAmount () {
            return this[this.paymentType].totalinvamount > 0 ?  Math.abs(this[this.paymentType].totalinvamount) : Math.abs(this[this.paymentType].totalamount)
        },
        S3Client(){
            return new S3(this.config);
        },
        supplierPaymentContacts () {
            return this.supplierContacts.filter(contact => {
                return contact?.Contact?.otsnotification == 1
            })
        },
        totalOutstanding () {
            const amount = this[this.paymentType].totalinvamount > 0 ?  Math.abs(this[this.paymentType].totalinvamount) : Math.abs(this[this.paymentType].totalamount)
            const outstanding =  amount - Math.abs(this.totalPaid)
            if(isNaN(outstanding)) return 0
            return outstanding
        },
        totalOutstandingWithAdjustment () {
            const amount = this[this.paymentType].totalinvamount > 0 ?  this[this.paymentType].totalinvamount : this[this.paymentType].totalamount
            const outstanding = (amount + parseFloat(this.Contract.adjustment)) - this.totalPaid
            if(isNaN(outstanding)) return 0
            return outstanding
        }
    },
    methods: {
        ...mapActions('currency',{
            getAllCurrencies: 'getAllCurrencies'
        }),
        ...mapActions('paymenttransaction',{
            addTransactionRow: 'addTransactionRow',
            deleteTransaction: 'deleteTransaction',
            deleteTransactionRow: 'deleteTransactionRow',
            sendPaymentReceivedNotificationToBuyer: 'sendPaymentReceivedNotificationToBuyer',
            sendTransactionSlipToSupplier: 'sendTransactionSlipToSupplier',
            updateTotals: 'updateTotals'
        }),
        addNewTransactionRow () {
            this.loading.add = true
            let payment = this.paymentType == 'PoPayment' ? this.PoPayment : this.Payment
            let payload = {
                prefix: this.statePrefix,
                paymentType: this.paymentType,
                paymentId: payment.id,
                keyId: payment.key_id,
                payerId: payment.payer_id,
                payeeId: payment.payee_id
            }
            this.addTransactionRow( payload )
                .then(()=>{
                    this.loading.add = false
                })
        },
        checkFile(file) {
            const allowedExtensions = this.validationRules.validUploadFileExtensions
            if(allowedExtensions.exec(file.name) == null) {
                return 'pdf file only'
            }
            return ''
        },
        async deleteThisTransaction (itemIndex, itemId) {
            let _this = this
            if (await this.$root.$confirm(this.$t('message.delete'), this.$t('message.confirmations.continueDeleteTransactionAction'), {color: 'red'})) {
                const promise = new Promise((resolve, reject) => {
                    try {
                        const payload = {
                            index: itemIndex,
                            prefix: _this.statePrefix,
                            paymentType: this.paymentType
                        }
                        this.deleteTransactionRow(payload)
                        resolve('done')
                    } catch(err) {
                        reject(err)
                    }
                });
                promise
                    .then(()=>{
                        let thisPayload = {
                            prefix: _this.statePrefix,
                            paymentType:this.paymentType
                        }
                        if(itemId != null){
                            let payment = this.paymentType == 'PoPayment' ? this.PoPayment : this.Payment
                            this.deleteTransaction({
                                    paymentId: payment.id,
                                    itemId: itemId
                                })
                                .then((response)=>{
                                    _this.updateTotals(thisPayload)
                                })
                        } else {
                            _this.updateTotals(thisPayload)
                        }
                    })
            }
        },
        dialogClosed () {
            this.dialogs.error = false;
            this.dialogs.error_message = ''
        },
        formatDate,
        formatPaymentDate (date) {
            return formatDate(date)
        },
        formatThisNumber (value,format) {
            return numberFormat(value,format)
        },
        getBuyerContacts () {
            let company_id = this.Contract.Customer.company_id
            if(!company_id){
                company_id = this.Localsale.Customer.company_id
            }
            return new Promise((resolve, reject) => {
                api
                    .get("/companies/" + company_id + "/contacts")
                    .then(response => {
                        this.buyerContacts = response.data.data
                        resolve('done');
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        resolve('done');
                    })
            })
        },
        getPaymentCurrency () {
            let paymentCurrency = null
            if(this.paymentType == 'PoPayment'){
                let payment = this.PoPayment
                paymentCurrency = this.allCurrencies.find(c => c.Currency.id == payment.currency_id)
            } else if(this.paymentType == 'Payment'){
                if(this.Contract?.salestype_id == 5){
                    paymentCurrency = this.allCurrencies.find(c => c.Currency.id == this.Contract.currency_id)
                } else {
                    let payment = this.Payment
                    paymentCurrency = this.allCurrencies.find(c => c.Currency.id == payment.currency_id)
                }
            }
            return {
                id: paymentCurrency?.Currency.id,
                code: paymentCurrency?.Currency.code,
                format: paymentCurrency?.Currency.formatPrecision
            }
        },
        getSupplierContacts () {
            let company_id = this.Contract.Supplier.company_id
            if(this.documentType == 'Epr') {
                company_id = this.Epr.Supplier.company_id
            }
            return new Promise((resolve, reject) => {
                api
                    .get("/companies/" + company_id + "/contacts")
                    .then(response => {
                        this.supplierContacts = response.data.data
                        resolve('done');
                    })
                    .catch(error => {
                        reject(error);
                    })
                    .finally(() => {
                        resolve('done');
                    })
            })
        },
        openNotificationToBuyerDialog (idx, transactionId) {
            this.sendBuyerNotificationDialog = true
            this.selectedTransactionId = transactionId
        },
        openSendSlipDialog (idx, transactionId) {
            this.sendSlipDialog = true
            this.selectedTransactionId = transactionId
        },
        paymentDepositSet(status) {
            if(status == true){
                this.$emit('dp-paid-set')
            } else {
                this.$emit('dp-paid-unset')
            }
        },
        sendNotificationToBuyer() { //Only for Invoice Payments
            if(this.selectedBuyerContacts.length == 0){
                this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                this.dialogs.error = true
            } else {
                this.loading.sendToBuyer = true
                let payload = {
                    contacts: this.selectedBuyerContacts,
                    itemId: this.selectedTransactionId,
                    paymentId: this.Payment.id
                }
                this.sendPaymentReceivedNotificationToBuyer(payload)
                    .then((status)=>{
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.notificationSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.loading.sendToBuyer = false
                            this.sendBuyerNotificationDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.notificationNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.loading.sendToBuyer = false
                            this.sendBuyerNotificationDialog = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.notificationNotSent'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.sendSlipDialog = false
                    })
                    .finally(() => {
                        this.loading.send = false
                        this.selectedSupplierContacts = []
                        this.sendSlipDialog = false
                    })
            }
        },
        sendPaymentSlipToSupplier() { //Only for PO payments
            if(this.selectedSupplierContacts.length == 0){
                this.dialogs.error_message = this.$t('message.errors.pleaseSelectOneContact');
                this.dialogs.error = true
            } else {
                this.loading.send = true
                let payload = {
                    contacts: this.selectedSupplierContacts,
                    itemId: this.selectedTransactionId,
                    paymentId: this.PoPayment.id
                }
                this.sendTransactionSlipToSupplier(payload)
                    .then((status)=>{
                        if (status == 'done') {
                            this.$toast.success(this.$t('message.successes.slipSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'check_circle_outline'
                                }
                            )
                            this.sendSlipDialog = false
                        } else {
                            this.$toast.error(this.$t('message.errors.slipNotSent'),
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                            )
                            this.sendSlipDialog = false
                        }
                    })
                    .catch(() => {
                        this.$toast.error(this.$t('message.errors.slipNotSent'),
                            {
                                classes: ['icon-float-left'],
                                icon: 'error_outline'
                            }
                        )
                        this.sendSlipDialog = false
                    })
                    .finally(() => {
                        this.loading.send = false
                        this.selectedSupplierContacts = []
                        this.sendSlipDialog = false
                    })
            }
        },
        updateTransactionRowHandle (idx, field, value) {
            let payload = {}
            switch(field) {
                case 'Paymenttransaction.payment_slip':
                    //Upload slip directly to AWS S3
                    const allowedExtensions = this.validationRules.validUploadFileExtensions
                    this.loading.upload[idx] = true
                    let file = value
                    let fileLocation = null
                    if(file?.name != null && file.name != '' && allowedExtensions.exec(file.name) != null) {
                        let newFileName = `${file['name'].split('.').slice(0, -1).join()}-${Math.random().toString().slice(2, 5)}`
                        let assetDocument = {}
                        let doctype_id = this.paymentType == 'PoPayment' ? 96 : 97
                        let document = this.allFinanceDocuments.find(d => d.Document.doctype_id == doctype_id)
                        let asset = 'payment'
                        this.S3Client
                            .uploadFile(file, newFileName)
                            .then((response) => {
                                if (response.status == 204) {
                                    assetDocument.id = null
                                    assetDocument.asset = asset
                                    let payment = this.paymentType == 'PoPayment' ? this.PoPayment : this.Payment
                                    assetDocument.asset_id = payment.id
                                    assetDocument.doctype_id = document.Document.doctype_id
                                    assetDocument.asset_title = document.Document.title
                                    assetDocument.location = response.location
                                    assetDocument.uploaded = Date.now()
                                    let type = file.type
                                    assetDocument.asset_type = type.split('/')[1]
                                    fileLocation = response.location
                                }

                                let assetPayload = {
                                    index: idx,
                                    field: 'AssetDocument',
                                    paymentType: this.paymentType,
                                    prefix: this.statePrefix,
                                    value: assetDocument,
                                }
                                this.$store.commit('paymenttransaction/updateTransactions', assetPayload)
                                this.loading.upload[idx] = false
                            })
                            .catch(() => {
                                this.loading.upload[idx] = false
                            })
                            .finally((data) => {
                                this.loading.upload[idx] = false
                            })
                        const _this = this
                        setTimeout(function(){
                            _this.loading.upload[idx] = false
                        },1000)
                    } else if(file?.name != null && file.name != '' && allowedExtensions.exec(file.name) == null){
                        let assetPayload = {
                            index: idx,
                            field: 'AssetDocument',
                            paymentType: this.paymentType,
                            prefix: this.statePrefix,
                            value: {},
                        }
                        this.$store.commit('paymenttransaction/updateTransactions', assetPayload)
                        this.$toast.error('PNG or JPG images or PDF only.',
                                {
                                    classes: ['icon-float-left'],
                                    icon: 'error_outline'
                                }
                        )
                        this.loading.upload[idx] = false
                    } else {
                        let assetPayload = {
                            index: idx,
                            field: 'AssetDocument',
                            paymentType: this.paymentType,
                            prefix: this.statePrefix,
                            value: {},
                        }
                        this.$store.commit('paymenttransaction/updateTransactions', assetPayload)
                        this.loading.upload[idx] = false
                    }
                    payload = {
                        index: idx,
                        field: field,
                        paymentType: this.paymentType,
                        prefix: this.statePrefix,
                        value: fileLocation
                    }
                    break
                default:
                    payload = {
                        index: idx,
                        field: field,
                        paymentType: this.paymentType,
                        prefix: this.statePrefix,
                        value: value,
                    }
            }
            const promise = new Promise((resolve, reject) => {
                try {
                    this.$store.commit('paymenttransaction/updateTransactions', payload)
                    resolve('ok')
                } catch {
                    reject('error')
                }
            });
            const _this = this
            if(!['Paymenttransaction.paymentdate','Paymenttransaction.payment_slip'].includes(field)) {
                promise
                    .then(() => {
                        const thisPayload = {
                            prefix: _this.statePrefix,
                            paymentType: _this.paymentType
                        }
                        _this.updateTotals(thisPayload)
                    })
                    .catch()
            }
        },
        updateLoadingUpload () {
            this.loading.upload = []
            this.PaymentTransactions.forEach((p) => {
                this.loading.upload.push(false)
            })
        },
        viewPaymentSlip (file) {
            let tab = window.open(file, '_blank')
            tab.name = 'tab_' + Math.floor(Math.random() * 10000)
            this.openedTabs.push(tab.name)
        }
    },
    watch: {
        validate(value) {
            // console.log('Validating: ' + value)
            if(value) {
                if (this.PaymentTransactions.length > 0) {
                    const _this = this
                    const validateTransactions = async function () {
                        if (value) {
                            return await _this.$refs.paymentTransactionsForm.validate()
                        }
                    }
                    validateTransactions()
                            .then((isFormValid) => {
                                // console.log('Is Form valid? ' + isFormValid)
                                if (isFormValid) {
                                    // console.log('Emitting transactions-valid')
                                    this.$emit('transactions-valid')
                                } else {
                                    // console.log('Emitting transactions-invalid')
                                    this.$emit('transactions-invalid')
                                }
                            })
                } else {
                    this.$emit('transactions-valid')
                }
            }
        }
    },
    created() {
        if(this.allCurrencies.length == 0) this.getAllCurrencies()
    },
    mounted () {
        this.$store.watch(
            function (state) {
                return state.appic.paymenttransaction.update.Payment
            },
            () => {
                this.updatedPaymentUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.paymenttransaction.update.PoPayment
            },
            () => {
                this.updatedPaymentUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.paymenttransaction.current.Payment
            },
            () => {
                this.newPaymentUnsaved = true
            },
            {deep: true}
        )
        this.$store.watch(
            function (state) {
                return state.appic.paymenttransaction.current.PoPayment
            },
            () => {
                this.newPaymentUnsaved = true
            },
            {deep: true}
        )
        const _this = this
        setTimeout(function(){
            if(_this.paymentType == 'PoPayment') {
                _this.getSupplierContacts()
                        .then(()=>{
                            _this.supplierPaymentContacts.forEach(c => {
                                _this.selectedSupplierContacts.push(c.Contact.email)
                            })
                            _this.updateLoadingUpload()
                        })
            }
            if(_this.paymentType == 'Payment'){
                _this.getBuyerContacts()
                        .then(() => {
                            _this.buyerPaymentContacts.forEach(c => {
                                _this.selectedBuyerContacts.push(c.Contact.email)
                            })
                        })
            }
        },2000)
    }
}
</script>

<style>
    .bg-lightgrey {
        background-color: #F5F5F5;
    }
    .col-lg-4-5 {
        flex: 0 0 37.5;
        max-width: 37.5% !important;
    }
    .col-lg-7-5 {
        flex: 0 0 62.5;
        max-width: 62.5% !important;
    }
    .v-text-field__slot input {
        text-align: right;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:before {
        border-style: none;
    }
    .custom.v-text-field>.v-input__control>.v-input__slot:after {
        border-style: none;
    }
    .v-data-table td {
        padding: 0 5px;
    }
    .font-xs {
        font-size: 0.65rem;
    }
    .mt-5px {
        margin-top: 5px !important;
    }
    .mt-6px {
        margin-top: 6px !important;
    }
    .custom.v-select .v-icon {
        font-size: 18px !important;
    }
    .truncate {
        width: 100px;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
    }
    .v-data-table tbody td {
        vertical-align: middle !important;
        padding-left: 5px !important;
        padding-right: 5px !important;
        height: 40px !important;
    }
    .dense-inputs .v-input {
        font-size: 1em !important;
        padding-top: 0px !important;
    }
</style>